.filter-wrapper {
    display: flex;
    > div {
        min-width: 200px;
        margin-right: 20px;
    }
}

.statusContainer {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.tableStatusActive {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: #299f72;
}

.tableStatusInactive {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: gray;
}