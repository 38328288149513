$error-color: #ff6775;

.header {
    color: #4a70ae;
    font-weight: 600;
    font-size: 16px;
}

.h3 {
    font-weight: 600;
}

.editor {
    .public-DraftStyleDefault-block {
        min-height: 200px;
    }
}

.table {
    margin-top: 10px;
    width: 100%;
    th {
        font-weight: 400;
        text-align: right;
        padding-right: 15px;
        width: 1px;
        word-break: keep-all;
        white-space: nowrap;
        font-size: 14px;
    }
    td {
        padding-bottom: 10px;
    }
}

.hint {
    vertical-align: top;
}

.textArea {
    max-width: 500px;
}

.formInput {
    width: 300px !important;
}

.fileButton {
    padding: 0.3rem .5rem !important;
}

.fileName {
    display: inline;
    width: 300px !important;
    margin-right: 10px;
    min-height: calc(1.5em + 1.3rem + 2px);
    height: auto !important;
}

.fileContainer {
    display: flex;
    align-items: center;
    > label {
        margin-bottom: 0;
    }
}

.errorMessage {
    color: $error-color;
}

.formSelectError {
    > div {
        border: 1px solid $error-color;
    }
}

.formError {
    border: 1px solid $error-color !important;
}

.formEditorError {
    border: 1px solid $error-color !important;
}