.rowContainer {
    margin-top: 5px;
    display: flex;
    align-items: center;
    background: #fff !important;
}

.radioInput {
    width: 300px !important;
}

.scoreInput {
    width: 70px !important;
    margin-left: 10px;
}

.choiceInput {
    width: 140px !important;
}

.premiseInput {
    width: 250px !important;
}

.imagePreview {
    margin-left: 40px;
    width: 150px;
    height: 150px;
}

.imageContainer {
    display: flex;
    width: min-content;
    margin-top: 5px;
}

.readOnly {
    display: inline;
    width: 300px !important;
}