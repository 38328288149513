.search-component {
  margin: auto;
  width: 100%;
}
.form-component {
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
  }

  form label {
    padding-left: 15px;
    padding-right: -10px;
  }
}