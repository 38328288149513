.class-info-table {
    width: 100%;
    table-layout: fixed;
    > tbody {
        > tr {
            td, th {
                width: 50%;
            }
            td {
                text-align: right;
                padding-right: 15px;
            }
            th {
                padding-left: 15px;
            }
        }
    }
}

.mv-table-icon {
    font-size: 30px;
}