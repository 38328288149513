.add-entry-h {
  margin-top: 41px;
  width: 100%;
}

.select-school {
  margin: auto;
  text-align: center;
  position: absolute;
  width: 15%;
}
.entry-type {
  margin: auto;
  text-align: center;
  position: absolute;
  width: 15%;
  margin-top: 8px;
}
.date-range-picker {
  margin: auto;
  text-align: center;
  margin-left: 1.8em;
  width: 100%;
  margin-top: 8px;
}
.label-text {
  display: relative;
  position: absolute;
  margin: auto;
  margin-top: 12px;
}
.test {
  margin: auto;
  text-align: center;
}
