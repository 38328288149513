.nav-link.active>#card-btn {
    color: var(--grey-text) !important;
    border: 1px solid var(--info) !important;
    border-radius: 10px !important;
}

.bc {
    padding-left: calc(var(--bs-gutter-x) / 2);
}

.nav-item {
    /* border-bottom: 1px solid rgba(255, 73, 0, 0.05); */
    margin: 0 0 -2px;
}

.nav-item-height {
    height: 51px !important;
}

.nav-item-height:hover>.d-none {
    display: block !important;
}

.nav-item-height:hover>.d-flex.align-items-center.ms-2>.d-block {
    display: none !important;
}

.top-btn {
    border-radius: 8px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-family: 'Pinnacle-DemiBold';
    font-size: 12px;
}

.card-custom-padding {
    padding: 1rem 1.5rem 0.85rem 1.5rem !important;
}

.card-heading-text {
    font-family: 'Pinnacle-DemiBold';
    font-size: 1vw;
    margin-bottom: 0;
}

.card-btn-header {
    font-family: 'Pinnacle-Bold';
    font-size: 14px;
    padding-bottom: 5px;
}

.search-btn-custom-color {
    box-shadow: inset 0 0 0 1px #5867dd !important;
    color: #5867dd !important;
}

.search-btn-custom-color:hover {
    background-color: #5867dd;
}

.custom-badge-shop {
    min-width: 28px !important;
    height: 23px !important;
    background-color: var(--secondary) !important;
    color: white;
    padding: 3px !important;
    font-family: 'Pinnacle-Bold';
}

.active-text {
    font-size: 21px;
    font-weight: 700;
    color: var(--info);
}

.active-text-description {
    font-family: var(--font);
    font-size: 12px;
    color: #b2b3b2;
    text-transform: uppercase;
}

.inactive-text {
    font-size: 21px;
    font-weight: 700;
    color: #868aa8;
}

.inactive-text-description {
    font-family: var(--font);
    font-size: 12px;
    color: #b2b3b2;
    text-transform: uppercase;
}
.custom-active-tab {
    color: var(--info);
    border: 1px solid rgba(255, 91, 29, 0.5);
    border-radius: 10px;
}

.border-top-info {
    border-top: 1px solid rgba(255, 91, 29, 0.5);
}

.custom-erp-title {
    height: 50px;
}

.header-title {
    color: #ff4900;
    font-weight: bold;
}