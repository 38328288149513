.msue-timetable {
    margin-top: 2rem;
    > tbody {
        > tr {
            td {
                padding: .3rem;
                border: 1px solid #ebedf2;
            }
        }
    }
    > thead {
        > tr {
            th {
                padding: .5rem;
                border: 1px solid #ebedf2;
            }
        }
    }
}

.msue-timetable-cell {
    border-radius: 6px;
    padding: 10px;
    border-color: transparent;
    > div {
        text-align: center;
        font-weight: 500;
    }
}

.timetable-add {

}

.w-250 {
    width: 250px;
}

.timetable-add-subject-programs {
    border-radius: 10px;
    background: rgba(145, 155, 192, 0.3);
    > tbody {
        > tr {
            &:first-of-type {
                td, th {
                    padding-top: 15px;
                }
            }
            &:last-of-type {
                td, th {
                    padding-bottom: 15px;
                }
            }
        }
    }
    td, th {
        padding: 5px 10px;
    }
    td {
        text-align: right;
        padding-left: 25px;
    }
    th {
        padding-right: 25px;
    }
}
.timetable-add-ptimes {
    width: 100%;
    margin: 2rem 0;
    > thead {
        > tr {
            th {
                padding-bottom: 10px;
            }
        }
    }
    > tbody {
        > tr {
            td {
                padding-bottom: 4px;
            }
        }
    }
}