.msue-form-date-picker {
    display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.4rem 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #464E5F;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #919bc0;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 16px
}

.msue-timepicker-input {
    display: block;
    text-align: center;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #464E5F;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #919bc0;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    user-select: none !important;
    &.active {
        border-color: #69b3ff;
    }
    &:hover {
        cursor: pointer;
    }
}

.msue-timepicker-popout {
    margin-top: 5px;
    padding: 4px;
    width: 175px;
    box-shadow: 0 0 15px 1px rgba(81, 77, 92, .2);
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border-radius: 6px;
    > table {
         width: 100%;
         border-collapse: collapse;
         color: #575962;
         table-layout: fixed;
         -webkit-touch-callout: none; /* iOS Safari */
         -webkit-user-select: none; /* Safari */
         -moz-user-select: none; /* Old versions of Firefox */
         -ms-user-select: none; /* Internet Explorer/Edge */
         user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
        td {
            text-align: center;
            height: 30px;
            margin: 0;
            padding: 2px;
            > div {
                width: 100%;
                display: flex;
                margin: 0;
                padding: 8px 0;
                outline: 0;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                &:hover {
                    background: #ebedf2;
                    cursor: pointer;
                }
            }
        }
        > tbody {
            > tr {
                > td {
                    &:nth-of-type(2) {
                        width: 20px;
                    }
                }
                &:nth-of-type(2) {
                    > td:first-child, td:last-child {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}