.custom-card {
  cursor: pointer;
}

.custom-card:hover {
  background-color: #f4f4f4;
}

.custom-card.border-info {
  pointer-events: none;
}

.full-page-bg {
  background: #fff8f5;
  opacity: 1 !important;
}

.custom-full-page-modal {
  padding: 20px;
}

.custom-full-page-modal .modal-content {
  border-radius: 6px;
  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
}

.custom-container {
  border-radius: 8px;
  border: solid 1px rgba(24, 125, 228, 0.1);
  padding: 20px;
  position: relative;
}

.custom-container-error {
  border-radius: 8px;
  border: solid 1px rgba(202, 3, 3, 1);
  padding: 20px;
  position: relative;
}

.custom-container .react-datepicker__input-container input {
  border: 1px solid #E5EAEE !important
}

.custom-container-error .react-datepicker__input-container input {
  border: 1px solid #E5EAEE !important
}

.custom-badge {
  padding: 4px 8px 3px;
  border-radius: 4px;
  background-color: #575962;
  color: #f4f5f8;
  font-size: 13px;
}

.custom-badge-active {
  background-color: #7ed321;
}

.text-red {
  color: #dc3545;
}
.custom-q * {
  font-size: 14px;
}

.custom-q h4 {
  font-size: 16px;
}

.space-x-4 * {
  margin: 0 10px;
}

.space-x-4 *:first-child {
  margin: 0;
}

.space-x-4 *:last-child {
  margin: 0;
}

@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 25px;
  --c: darkred;
  --w: 150px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
}
.pie:before,
.pie:after {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  /* transform: scale(-1); */
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}
.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: scale(-1) rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
}
.animate {
  animation: p 1s 0.5s both;
}
.no-round:before {
  background-size: 0 0, auto;
}
.no-round:after {
  content: none;
}
@keyframes p {
  from {
    --p: 0;
  }
}

.mr-5 {
  margin-right: 5px;
}

.custom-popover {
  width: 243px;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: none;
}

.custom-popover .popover-arrow {
  display: none;
}

.btn-more-container {
  position: absolute;
  top: 80px;
  right: 20px;
}
.btn-more {
  position: relative;
  display: flex;
  flex-direction: column;
  background: none;
  outline: none;
  border: none;
  top: -60px;
}

.btn-more:hover {
  opacity: 0.8;
}

.btn-more:active {
  opacity: 1;
}

.btn-more img {
  width: 6px;
  height: 22px;
}

.dt-cm-item {
  display: flex;
  align-items: center;
  padding: 5px 1.5rem 5px 1rem;
  font-weight: 500;
}
.dt-cm-item span {
  margin-left: 15px;
}
.dt-cm-item svg {
  position: relative;
  top: 2px;
}
.dt-cm-item:hover {
  cursor: pointer;
  background: #f4f6f9;
}

.custom-nested-modal {
  z-index: 99999999;
}

.underline {
  text-decoration: underline;
}

.custom-q2 {
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px rgba(24, 125, 228, 0.5);
  background-color: #fff;
  width: 100%;
}

.loader-container {
  z-index: 999999999999999999;
}

.line {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #47c6ad;
  display: block;
}

.custom-status {
  /* height: 25px; */
  border-radius: 4px;
  background-color: #575962;
  padding: 5px 16px;
  color: #f4f5f8;
  font-weight: bold;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.custom-status.SENT {
  background-color: rgba(62, 191, 163, 0.8);
}

.custom-status.IN_PROGRESS {
  background-color: rgba(113, 106, 202, 0.8);
}

.custom-status.NEW {
  background-color: #f4516b;
}

.custom-forms {
  max-width: 100%;
}
.custom-forms label {
  flex: 0 !important;
  min-width: 200px;
  display: block;
}

.custom-forms label + div {
  /* flex: 0 !important; */
}

.custom-blue-btn {
  box-shadow: inset 0 0 0 1px #36a3f7 !important;
  color: #36a3f7 !important;
}

.custom-blue-btn:hover,
.custom-blue-btn:active {
  box-shadow: inset 0 0 0 1px #36a3f7 !important;
  background-color: #36a3f7 !important;
  color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white:hover {
  border-color: #f4f4f4 !important;
}

.date-picker-range-container .react-datepicker-wrapper:has(input) {
  /* width: 50% !important; */
  position: relative;
}

/* .date-picker-range-container .react-datepicker-wrapper:nth-child(even) {
  flex: 1;
}

.date-picker-range-container .react-datepicker__tab-loop + .react-datepicker-wrapper {
  flex: 1;
} */
