.my-form {
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;

    label {
      padding-left: 15px;
      padding-right: -10px;
    }
  }
}
