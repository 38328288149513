.mb-renderer {
    -moz-writing-mode: vertical-lr; 
    writing-mode: vertical-lr;
    -webkit-writing-mode: vertical-lr;
    -o-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: tb-lr;
    -webkit-text-orientation: sideways-right; 
    font-family: 'Mongolian Baiti';
    text-align: center;
    font-size: 18pt;
    line-height: 18pt;
}

.diploma-content-container {
}

.mb-renderer .public-DraftStyleDefault-block {
    text-align: center;
}

.diploma table td{
    border: solid 1px #000;
    padding: 1.5pt;
    font-size: 12pt;
}

.diploma table td .public-DraftStyleDefault-block{
    margin: 0;
}
