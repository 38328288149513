table.custom-dt {
    height: 1px;
    > thead {
        > tr {
            > td, th {
                border: 1px solid #ebedf2;
                background: #fff;
            }
        }
    }
    > tbody {
        > tr {
            > td, th {
                border: 1px solid #ebedf2;
                background: #fff;
            }
        }
    }
}

table .row-disabled td{
    background: #EDEDED !important;
}

.custom-size-per-page {
    > button {
        background-color: #fff !important;
    }
}

.dt-cm-wrapper {
    position: absolute;
    background: #fff;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0 1px 10px 0 rgba(145, 155, 192, 0.3);
    z-index: 100000;
    > .dt-cm-item {
        &:first-of-type {
            //padding-top: 5px;
        }
        &:last-of-type {
            //padding-bottom: 5px;
        }
    }
}
.dt-cm-item {
    display: flex;
    align-items: center;
    padding: 5px 1.5rem 5px 1rem;
    font-weight: 600;
    &:hover {
        //color: rgb(42, 69, 217);
        cursor: pointer;
        background: #e2dfdf;
    }
}

tr.dt-drag-hover-top {
    > td {
        border-top: 2px solid blue !important;
    }
}

tr.dt-drag-hover-bottom {
    > td {
        border-bottom: 2px solid blue !important;
    }
}

.dt-cm-item-icon {
    margin-right: .5rem;
    color: #1e1e2d;
    font-size: 25px;
}
.dt-cm-item-icon-18 {
    margin-right: .5rem;
    color: #1e1e2d;
    font-size: 18px;
}
.dt-cm-item-icon-19 {
    margin-right: .5rem;
    color: #1e1e2d;
    font-size: 19px;
}
.dt-cm-item-icon-20 {
    margin-right: .5rem;
    color: #1e1e2d;
    font-size: 20px;
}
.height-0 {
    height: 0;
}