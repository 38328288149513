.pageBackground {
  position: "absolute";
  min-width: "100%";
  min-height: "100%";
  display: "block";
  height: "100%";
  width: "100%";
}

.cert-container {
  height: 730px;
  padding-top: 70px;
}

.cert-container-bg {
  // background-image: url("/media/bg/bg-certificate.png");
  // background-image: url("./certificate.jpg");
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-size: cover;
  // background: url("./bg-certificate.png") center center cover no-repeat fixed;
  height: 730px;
  padding-top: 70px;
}

.content-text {
  // padding: 100px;
  // margin: 50px;
  width: 100%;
  text-align: center;
  p {
    margin-bottom: 0;
  }
}
